<template>
  <div>
    <div class="col-12 g" v-if="devices.length">
      <div class="alert alert-danger g" style="padding: 20px">
        <h5>هناك اجهزة بصمة غير متصلة وقد تؤثر على التقارير:</h5>
        <ul>
          <li v-for="(device, index) in devices" :key="index">
            اسم الجهاز: {{ device.title }}
            <br />
            الرقم التسلسلي: {{ device.serial_number }}
            <br />
            آخر نشاط: <span v-html="device.last"></span>
          </li>
        </ul>
      </div>
    </div>
    <b-card title="📃 تقارير الحضور والغياب والتأخر">
      <div class="row">
        <div class="col-12 col-lg-4 c">
          <div class="form-group">
            <h5 for="">إختر التاريخ</h5>
            <input
              type="date"
              class="form-control form-control-lg"
              ref="date"
              id="date"
            />
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12 col-lg-6 c text-center">
          <button class="btn btn-lg btn-primary" @click="viewReport()">
            عرض التقرير الآن
          </button>
        </div>
      </div>
    </b-card>
    <b-card v-if="table">
      <div class="col-12 text-center g" v-if="loading">
        <img
          src="../../assets/images/loading.svg"
          style="width: 60px; height: 60px"
          alt=""
        />
        <br />
        جاري البحث..
      </div>
      <div class="col-12 g text-center" v-if="reports.length > 0">
        <button class="btn btn-primary" @click="print('all', true)">
          طباعة التقرير اليومي
        </button>
        &nbsp;
        <button class="btn btn-info" @click="print('all')">
          طباعة الجدول كاملاً
        </button>
        &nbsp;
        <button class="btn btn-success" @click="print('1')">
          طباعة الحضور
        </button>
        &nbsp;
        <button class="btn btn-warning" @click="print('2')">
          طباعة التأخر
        </button>
        &nbsp;
        <button class="btn btn-danger" @click="print('3')">طباعة الغياب</button>
        &nbsp;
        <button class="btn btn-secondary" @click="addLate()" v-b-modal.modal-6>اضافة التأخر لنور</button>&nbsp;
        <button class="btn btn-success" @click="exportExcel()">تصدير الجدول</button>
        <br />
        <div class="col-12 col-lg-6 c g text-center">
          <div class="form-group">
            <label for="">فرز بمجموعة الإعدادات</label>
            <select
              class="form-control"
              name=""
              v-model="selected_group"
              @change="ga()"
              id=""
            >
              <template v-for="group in groups">
                <option :value="group._id" :key="group._id">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div
        class="col-12 table-responsive"
        ref="table"
        id="table"
        v-if="reports.length > 0"
      >
        <table class="table table-hover table-striped" id="table">
          <thead>
            <th>م</th>
            <th>المعلم</th>
            <th>الحالة</th>
            <th class="no">طريقة التحضير</th>
            <th>توقيت الحضور</th>
            <th>توقيت الإنصراف</th>
            <th class="no" v-if="!exporting">خيارات</th>
          </thead>
          <tbody>
            <tr v-for="(report, index) in reports" v-bind:key="report._id">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ report.name }}
              </td>
              <td>
                <template v-if="report.status">
                    <span class="badge badge-secondary" v-if="report.status == 0"
                    >خارج وقت الدوام</span
                    >
                    <span class="badge badge-success" v-if="report.status == 1"
                    >حاضر</span
                    >
                    <span class="badge badge-warning lated" v-if="report.status == 2"
                    >متأخر</span
                    >
                    <div v-if="report.status == 2 && report.late_time > 0">
                    متأخر: {{ (report.late_time).toFixed(0) }} دقيقة ({{
                        (report.late_time / 60).toFixed(1)
                    }}
                    ساعة)
                    </div>
                    <span class="badge badge-danger" v-if="report.status == 3"
                    >غائب</span
                    >
                    <span class="badge badge-info" v-if="report.status == 6"
                    >اجازة</span
                    >
                    <span class="badge badge-primary" v-if="report.status == 5"
                    >غائب بعذر</span
                    >
                    <div v-if="report.reason">
                        {{ report.reason }}
                    </div>
                </template>
                <span
                  v-if="allowedtoedit"
                  class="text-danger"
                  style="margin-right: 3px; font-size: 6px"
                  title="تعديل"
                  @click="
                    changeStatus(
                      report.number,
                      report.status,
                      report.join.time,
                      report.join.time ? true : false,
                      report.join._id,
                      report.late_time,
                      report.notes
                    )
                  "
                  ><feather-icon icon="ToolIcon" size="15"
                /></span>
                <span v-if="report.notes">
                  <span v-if="report.notes != '' && report.notes != null">
                    <br />
                    {{ report.notes }}
                  </span>
                </span>
              </td>
              <td class="no">
                {{ report.join.from }}
              </td>
              <td>
                <strong>{{ report.join.time }}</strong>
              </td>
              <td>
                <strong>{{ report.leave ? report.leave.time : "" }}</strong>
              </td>
              <td class="no" v-if="!exporting">
                <!-- primary -->
                <b-dropdown text="خيارات" variant="primary">
                  <b-dropdown-item v-b-modal.modal-1 @click="details(report)">
                    <feather-icon icon="AlignRightIcon" size="20" />
                    عرض التفاصيل
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="ghiabAsk(report)"
                    v-if="report.status == '3'"
                  >
                    <feather-icon icon="InfoIcon" size="20" />
                    مسائلة غياب
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="lateAsk(report)"
                    v-if="report.status == '2'"
                  >
                    <feather-icon icon="InfoIcon" size="20" />
                    مسائلة تأخر
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <!-- basic modal -->
    <b-modal id="modal-6" hide-footer size="lg"><div style="display:none;" id="ff"></div>
      <div class="col-12 text-center g">
        <button class="btn btn-primary" @click="addLateNow()">
          اضافة التأخر الان
        </button>
      </div>
      <table class="table table-sm table-hover table-bordered">
        <thead>
          <th>
              المعلم
          </th>
          <th>
              النوع
          </th>
          <th>
              المدة
          </th>
        </thead>
        <tbody>
            <tr v-for="teacher in arr" :key="teacher.id">
                <td>
                    {{ teacher.name }}
                </td>
                <td>
                    <select v-model="teacher.type" style="border:none; width: 100%">
                        <option value="5">تأخير بدون عذر</option>
                        <option value="3">تأخير بعذر</option>
                    </select>
                </td>
                <td>
                    <span style="display: inline-block">
                        <input type="number" style="border:none; width: 100px" v-model="teacher.h" placeholder="ساعة">
                    </span>
                    <span style="display: inline-block">
                        <input type="number" style="border:none; width: 100px" v-model="teacher.m" placeholder="دقيقة">
                    </span>
                </td>
            </tr>
        </tbody>
      </table>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تفاصيل التقرير"
      ok-only
      size="lg"
      ok-title="حسناً، شكراً لك"
    >
      <b-card-text>
        <div class="col-12 table-responsive">
          <table class="table table-bordered table-hover">
            <tbody>
              <tr>
                <td>الإسم</td>
                <td>{{ r.name }}</td>
              </tr>
              <tr>
                <td>رقم الهوية</td>
                <td>{{ r.number }}</td>
              </tr>
              <tr>
                <td>الحالة</td>
                <td>
                  <span class="badge badge-secondary" v-if="r.status == 0"
                    >خارج وقت الدوام</span
                  >
                  <span class="badge badge-success" v-if="r.status == 1"
                    >حاضر</span
                  >
                  <span class="badge badge-warning" v-if="r.status == 2"
                    >متأخر</span
                  >
                  <span class="badge badge-info" v-if="r.status == 5"
                    >غائب بعذر</span
                  >
                  <div v-if="r.status == 2 && r.late_time > 0">
                    متأخر: {{ r.late_time }} دقيقة ({{
                      (r.late_time / 60).toFixed(1)
                    }}
                    ساعة)
                  </div>
                  <span class="badge badge-danger" v-if="r.status == 3"
                    >غائب</span
                  >
                  <span class="badge badge-primary" v-if="r.status == 6"
                    >اجازة</span
                  >
                </td>
              </tr>
              <tr>
                <td>طريقة الفحص</td>
                <td v-if="r.join">
                  {{ r.join.from }}
                </td>
              </tr>
              <tr>
                <td>توقيت التحضير</td>
                <td v-if="r.join">
                  <strong>{{ r.join.time }}</strong>
                </td>
              </tr>
              <tr>
                <td>توقيت الإنصراف</td>
                <td v-if="r.leave">
                  <strong>{{ r.leave ? r.leave.time : "" }}</strong>
                </td>
              </tr>
              <tr>
                <td>توقيت رفع التقرير للنظام</td>
                <td v-if="r.join">{{ r.join.back_time }}</td>
              </tr>
              <tr>
                <td>اليوم</td>
                <td v-if="r.join">{{ r.join.date }}</td>
              </tr>
              <tr>
                <td>الإعدادات عند الفحص</td>
                <td v-if="r.settings">
                  <li>
                    توقيت الحضور:
                    {{ r.settings.start_date }}
                  </li>
                  <li>
                    توقيت التأخر:
                    {{ r.settings.late_date }}
                  </li>
                  <li>
                    توقيت الغياب:
                    {{ r.settings.ghiab_date }}
                  </li>
                  <li>
                    توقيت الإنصراف:
                    {{ r.settings.end_date }}
                  </li>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-2" title="تعديل التقرير" hide-footer>
      <b-card-text>
        <div>
          <div class="alert alert-danger g text-center">
            نحن نخلي مسؤوليتنا في حالة العبث في التقارير.
          </div>
          <div class="form-group">
            <label for="">اختر التوقيت</label>
            <input type="time" class="form-control" v-model="ttime" />
          </div>
          <div class="form-group">
            <label for="">الحالة</label>
            <select class="form-control" v-model="sstatus">
              <option value="0">خارج وقت الدوام</option>
              <option value="1">حضور</option>
              <option value="2">تأخر</option>
              <option value="3">غياب</option>
              <option value="5">غياب بعذر</option>
              <option value="4">انصراف</option>
            </select>
          </div>
          <div class="form-group dnone" v-if="sstatus == 2">
            <label for="">دقائق التأخر</label>
            <input type="number" class="form-control" v-model="mins" />
          </div>
          <div class="form-group" v-if="sstatus == 5">
            <label for="">العذر</label>
            <input
              type="text"
              class="form-control"
              v-model="notes"
              placeholder="اكتب العذر هنا"
            />
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="changeStatusNow()">
              تعديل التقرير الآن
            </button>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
var moment = require("moment-hijri");

import {
  BCard,
  BCardText,
  BLink,
  BDropdown,
  VBModal,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      exporting: false,
      groups: [],
      viewed: [],
      shifts: {},
      allowedtoedit: true,
      table: false,
      loading: false,
      reports: [],
      r: {},
      info: {},
      arr: [],
      number: null,
      ttime: null,
      sstatus: null,
      ttype: null,
      mins: 0,
      devices: [],
      selected_group: null,
      notes: null,
      user: JSON.parse(localStorage.getItem('user')),
    };
  },
  methods: {
      exportExcel(){
          var g =this;
          g.exporting = true;
          var table_id = "table"
          $("#" + table_id + " tbody").prepend(`
          <tr id='removemenow'>${$(`#${table_id} thead`).html()}</tr>
          `)
      setTimeout(() => {
          var separator = ','
          // Select rows from table_id
          var rows = document.querySelectorAll('table#' + table_id + ' tr');
          // Construct csv
          var csv = [];
          for (var i = 0; i < rows.length; i++) {
              var row = [], cols = rows[i].querySelectorAll('td, th');
              for (var j = 0; j < cols.length; j++) {
                  var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                  data = data.replace(/"/g, '""');
                  // Push escaped string
                  row.push('"' + data + '"');
              }
              csv.push(row.join(separator));
          }
          var csv_string = csv.join('\n');
          // Download it
          var filename = $("title").text().split("—")[1] + '.csv';
          var link = document.createElement('a');
          link.style.display = 'none';
          link.setAttribute('target', '_blank');
          link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(() => {
          $("#removemenow").remove()
          g.exporting = false
          }, 5000);
      }, 1000);
      },
    viewReport() {
      this.reports = [];
      if (this.$refs.date.value == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء اختيار التاريخ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        var g = this;
        g.viewed = [];
        this.table = true;
        this.loading = true;
        axios
          .post(api + "/user/teachers/reports/daily", {
            jwt: this.user.jwt,
            date: this.$refs.date.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              var arr = [];
              r.data.response.forEach(function (a) {
                if (a.status != 4 && !g.viewed.includes(a.number)) {
                  g.viewed.push(a.number);
                  arr.push(a);
                }
              });
              g.reports = arr;
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    print(i, daily = false) {
      var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              border: 1px solid #ccc;
          }
          .no, svg ${!daily ? ",.dnone" : ""}{
            display: none;
          }
        `);
      printWindow.document.designMode = "on";
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      moment.locale("ar-SA");
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ];
      var d = new Date(g.$refs.date.value);
      var dayname = days[d.getDay()];
      var date =
        moment(this.$refs.date.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      if (i == "all") {
        var title = "حضور وتأخر وغياب";
      }
      if (i == "1") {
        var title = "حضور";
      }
      if (i == "2") {
        var title = "تأخر";
      }
      if (i == "3") {
        var title = "غياب";
      }
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; padding: 20px;'>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            وزارة التعليم
            <br><br>
            ${g.user.name}
          </h5>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 50%'>
          <br>
          <h2>تقرير ${title} الموظفين</h2>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          اليوم: ${dayname}
          <br><br>
          التاريخ: ${date}
          </h3>
        </div>
      </div>
      `);
      //var divContents = this.$refs.table.innerHTML;
      var divContents = document.getElementById("table")?.innerHTML;
      printWindow.document.write(divContents);
      printWindow.document.querySelectorAll("tr").forEach(function (a) {
        if (i != "all" && !a.innerHTML.includes("طريقة التحضير")) {
          if (i == "1") {
            if (!a.innerHTML.includes("حاضر")) {
              a.style.display = "none";
            }
          }
          if (i == "2") {
            if (!a.innerHTML.includes("متأخر")) {
              a.style.display = "none";
            }
          }
          if (i == "3" || i == 3) {
            if (!a.innerHTML.includes("غائب")) {
              a.style.display = "none";
            }
          }
        }
      });
      var teachersx = "";
      if(g.shifts){
      var daysx = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        if(g.shifts[daysx[d.getDay()]]){
          if(g.shifts[daysx[d.getDay()]].length){
            g.shifts[daysx[d.getDay()]].forEach(function(v){
              teachersx = teachersx + v + '<br>'
            })
          }
        }
      }
      printWindow.document.write(
        `<br></center>${
          daily
            ? `<h3 style='float: right; position: relative; right: 30px'>المعلم المناوب<br>${teachersx}</h3><h3 style='float: left; position: relative; left: 30px'>المشرف الزائر</h3>`
            : ""
        }</body>`
      );

      printWindow.document.write("</html>");
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 1000);
    },
    details(report) {
      this.r = report;
    },
    ghiabAsk(report) {
      var _g = this;
      _g.$router.push(
        "/_teachers/reports/ask?day=day&date=" +
          _g.$refs.date.value +
          "&number=" +
          report.number + '&type=ghiab'
      );
    },
    lateAsk(report) {
      var _g = this;
      _g.$router.push(
        "/_teachers/reports/ask?day=day&date=" +
          _g.$refs.date.value +
          "&number=" +
          report.number + '&type=late&time=' + report.join.time
      );
    },
    changeStatus(number, s, t, ta, d, m, q) {
      this.number = number;
      this.$bvModal.show("modal-2");
      this.ttime = t;
      this.sstatus = s;
      this.ttype = ta;
      this.iid = d;
      this.mins = m;
      this.notes = q
    },
    changeStatusNow() {
      if (!this.ttime) {
        alert("برجاء اختيار التوقيت");
      } else {
        if (!this.sstatus) {
          alert("برجاء اختيار الحالة");
        } else {
          var _g = this;
          axios
            .post(api + "/user/teachers/reports/change-status", {
              jwt: this.user.jwt,
              number: this.number,
              status: this.sstatus,
              time: this.ttime,
              type: this.ttype,
              date: this.$refs.date.value,
              id: this.iid,
              mins: this.mins,
              reason: this.notes,
            })
            .then(function (r) {
              if (r.data.status == 200) {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "حدث خطأ JWT",
                    icon: "WarningIcon",
                    variant: "danger",
                  },
                });
              } else {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "تم تعديل التقرير بنجاح",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                _g.viewReport();
              }
            })
            .catch(function () {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    },
    ga() {
      var arr = [];
      if (this.$refs.date.value == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء اختيار التاريخ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        var g = this;
        this.table = true;
        this.loading = true;
        axios
          .post(api + "/user/teachers/reports/daily", {
            jwt: this.user.jwt,
            date: this.$refs.date.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              r.data.response.forEach(function (a) {
                if (a.settings.code == g.selected_group) {
                  arr.push(a);
                }
              });
              g.reports = arr;
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function (e) {
            console.log(e)
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return {
        m: rminutes,
        h: rhours
      }
    },
    addLate(){
      var g = this;
      var arr = []
      g.reports.forEach(function(a){
        if(a.status == 2){
          arr.push({
            name: a.name,
            type: '5',
            h: g.timeConvert(a.late_time).h,
            m: g.timeConvert(a.late_time).m,
          })
        }
      })
      g.arr = arr;
    },
    addLateNow(){
      var g = this;
      document.getElementById('ff').innerHTML = `
      <form target='_blank' action='https://tahdir.net/add-late-teachers' id='ccaa' method='post'>
        <textarea name='arr'>${JSON.stringify(g.arr)}</textarea>
      </form>
      `;
      document.getElementById('ccaa').submit()
    }
  },
  created() {
      if(!checkPer("teachers|reports|daily")){
          this.$router.push('/NotPermitted')
          return false;
      }
    var _g = this, g = this;
    
      var daysx = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      axios
      .post(api + "/user/teachers/shifts", {
        jwt: g.user.jwt,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          try{
            if(r.data.response){
                g.shifts = JSON.parse(r.data.response);
            }
          }catch{
            daysx.forEach(function(a){
                g.shifts[a] = [];
            })
          }
        }
      })
      .catch(function () {
      });
    axios
      .post(api + "/user/teachers/groups", {
        jwt: _g.user.jwt,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.groups = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    axios
      .post(api + "/user/devices/not-connected", {
        jwt: _g.user.jwt
      })
      .then(function (data) {
        _g.devices = data.data.response;
        if (data.data.status == 200) {
          alert("حدث مشكلة اثناء الاتصال بالخادم.");
        }

        _g.$refs.date.value = new Date().toISOString().split("T")[0];
      })
      .catch(function (error) {
        alert("حدث مشكلة اثناء الاتصال بالخادم");
      });
  },

};
</script>

<style>
.dnone {
  display: none;
}
</style>
